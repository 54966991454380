import { createDerived, createStore, getValue, keepActive, update } from '@kundinos/nanostores';
import { useStore } from '@kundinos/nanostores/react';
import equal from 'fast-deep-equal';

import { Cache } from '../Cache';
import getFiltersNames from './helpers/getFiltersNames';

import type { FiltratorId, Stores, Values } from './typings';
import type { Instance } from '../Cache';
import type {
  FiltersData,
  FiltersGroup,
  FiltersSortItemData,
  FiltersParameterDefaultData,
  FiltersParameters,
  FiltersValue,
} from '@Types/Filters';

export type ResetOne = (entityId: FiltratorId, value: FiltersValue) => void;

interface ParametersData {
  name: string;
  value: number | string;
  flag: boolean;
}

interface RangeValuesData {
  from: string | number;
  to: string | number;
}

interface OptionsRangeData {
  id: string | number;
  from: number;
  to: number;
}

interface OptionsGroupData {
  group: FiltersGroup;
}

interface ExcludeParameterData {
  name: string;
  value?: number | string;
}

export const getParameterName = (parameterId: string): string => {
  return Number.isNaN(parseInt(parameterId, 10)) ? parameterId : `parameters[${parameterId}]`;
};

export function getStores(id: FiltratorId) {
  return Cache.getEntity<Stores>(id)?.stores;
}

export function cleanup(id: FiltratorId) {
  return Cache.clearEntity<Stores>(id);
}

export const formatFiltersToObject = (entityId: FiltratorId) => {
  const { filtratorStore } = getStores(entityId);
  const filtrator = getValue(filtratorStore);
  let items = {};

  // Выбранные фильтры
  Object.entries(filtrator.parameters).forEach(([parameterId, parameter]) => {
    const { value, type } = filtrator.parameterValues.find((pv) => pv.parameterId === parameterId);
    const isRange = type === 'range';
    const isVariant = type === 'variant';
    const isSwitch = type === 'switch';
    const defaultValues = parameter.default;
    const parameterName = Number.isNaN(parseInt(parameterId, 10)) ? parameterId : `parameters`;

    if (isRange) {
      let values: RangeValuesData = null;

      if (value[0] !== defaultValues[0]) {
        values = { ...values, from: defaultValues[0] };
      }

      if (value[1] !== defaultValues[1]) {
        values = { ...values, to: defaultValues[1] };
      }

      if (values) {
        if (parameterName !== parameterId) {
          values = { ...items[parameterName], [parameterId]: { ...values } };
        }

        items = {
          ...items,
          [parameterName]: values,
        };
      }
    }

    if (isVariant) {
      if (defaultValues.length > 0) {
        let values = defaultValues;
        if (parameterName !== parameterId) {
          values = { ...items[parameterName], [parameterId]: [...defaultValues] };
        }

        items = { ...items, [parameterName]: values };
      }
    }

    if (isSwitch) {
      items = { ...items, [parameterName]: defaultValues };
    }
  });

  // Сортировка
  filtrator.sort.forEach((item) => {
    if (item.selected && +item.id !== 0) {
      items = { ...items, sort: item.id };
    }
  });

  return items;
};

// Формирует URL для фильтров
export const toUrl = (entityId: FiltratorId, exclude: ExcludeParameterData[] = []): string => {
  const stores = getStores(entityId);
  const filtrator = getValue(stores.filtratorStore);
  const categories = getValue(stores.activeCategoryIdsStore);
  const initialSearchParams = new URLSearchParams(
    getValue(stores.initialSearchParamsStore).toString(),
  );

  function toggleItem({ name, value, flag }: ParametersData) {
    if (flag) {
      initialSearchParams.set(name, `${value}`);
    } else {
      initialSearchParams.delete(name);
    }
  }

  function isExcluded(name: string, value?: string | number) {
    return exclude.some((item) => {
      if (!value) return item.name === name;

      return item.name === name && item.value === value;
    });
  }

  // Выбранные категории
  categories.forEach((category) => {
    if (isExcluded('categories', category)) return;
    initialSearchParams.set(`categories[]`, category.toString());
  });

  // Выбранные фильтры
  Object.entries(filtrator.parameters).forEach(([parameterId, parameter]) => {
    const { value, type, initialValue } = filtrator.parameterValues.find(
      (pv) => pv.parameterId === parameterId,
    );

    const isRange = type === 'range';
    const isVariant = type === 'variant';
    const isSwitch = type === 'switch';
    const defaultValues = parameter.default;
    const parameterName = getParameterName(parameterId);

    if (isRange) {
      if (isExcluded(parameterName)) return;

      toggleItem({
        name: `${parameterName}[from]`,
        value: defaultValues[0],
        flag: value[0] !== defaultValues[0],
      });
      toggleItem({
        name: `${parameterName}[to]`,
        value: defaultValues[1],
        flag: value[1] !== defaultValues[1],
      });
    }

    if (isVariant) {
      defaultValues.forEach((defaultValue) => {
        if (isExcluded(parameterName, defaultValue)) return;
        initialSearchParams.append(`${parameterName}[]`, defaultValue.toString());
      });
    }

    if (isSwitch) {
      if (isExcluded(parameterName)) {
        if (initialValue?.toString() === '1') {
          initialSearchParams.set(`${parameterName}`, '0');
        }
        return;
      }

      if (defaultValues.toString() === initialValue?.toString()) {
        initialSearchParams.delete(`${parameterName}`);
      } else {
        initialSearchParams.set(`${parameterName}`, defaultValues.toString());
      }
    }
  });

  // Сортировка
  if (!isExcluded('sort')) {
    filtrator.sort.forEach((item) => {
      if (item.selected && +item.id === 0) return;
      if (item.selected) initialSearchParams.set('sort', item.id);
    });
  }

  return `?${initialSearchParams.toString()}`;
};

// Генерирует URL для указанных значений параметров
export const createUrlForValues = (
  entityId: FiltratorId,
  parameterValues: FiltersValue[],
): string => {
  const stores = getStores(entityId);
  const filtrator = getValue(stores.filtratorStore);
  const categories = getValue(stores.activeCategoryIdsStore);
  const selected = getValue(stores.selectedStore);
  const initialSearchParams = new URLSearchParams(getValue(stores.initialSearchParamsStore));

  function toggleItem({ name, value, flag }: ParametersData) {
    if (flag) {
      initialSearchParams.set(name, `${value}`);
    } else {
      initialSearchParams.delete(name);
    }
  }

  // Выбранные категории
  categories.forEach((category) => {
    initialSearchParams.set(`categories[]`, category.toString());
  });

  // Указанные значения параметров
  const parameterValuesSet = new Set([...selected.parameterValues, ...parameterValues]);
  const uniqParameterValues = Array.from(parameterValuesSet);

  uniqParameterValues.forEach((parameterValue) => {
    const isRange = parameterValue.type === 'range';
    const isVariant = parameterValue.type === 'variant';
    const isSwitch = parameterValue.type === 'switch';
    const parameter = filtrator.parameters[parameterValue.parameterId];
    const parameterName = getParameterName(parameterValue.parameterId);

    if (isRange) {
      toggleItem({
        name: `${parameterName}[from]`,
        value: parameter.default[0],
        flag: parameterValue.value[0] !== parameter.default[0],
      });
      toggleItem({
        name: `${parameterName}[to]`,
        value: parameter.default[1],
        flag: parameterValue.value[1] !== parameter.default[1],
      });
    }

    if (isVariant) {
      const isSelected = selected.parameterValues.includes(parameterValue);
      const isSpecified = parameterValues.includes(parameterValue);

      if (isSpecified && isSelected) return;

      parameterValue.value.forEach((defaultValue) => {
        initialSearchParams.append(`${parameterName}[]`, defaultValue.toString());
      });
    }

    if (isSwitch) {
      initialSearchParams.set(parameterName, parameter.default.toString());
    }
  });

  // Сортировка
  filtrator.sort.forEach((item) => {
    if (item.selected && +item.id === 0) return;
    if (item.selected) initialSearchParams.set('sort', item.id);
  });

  return `?${initialSearchParams.toString()}`;
};

// Вычисляет примененные фильтры
export const calculateAppliedValues = (
  entityId: FiltratorId,
  selected: Values['selected'],
): FiltersValue[] => {
  const { parameters, parameterValues } = selected;
  const values: FiltersValue[] = [];

  Object.keys(parameters).forEach((parameterId) => {
    const currentVariants = parameterValues.filter((val) => val.parameterId === parameterId);
    const parameter = parameters[parameterId];
    const parameterName = getParameterName(parameterId);

    switch (currentVariants[0].type) {
      case 'variant': {
        parameter.default.forEach((id) => {
          const value = currentVariants.find((variant) => variant.value.includes(id));

          values.push({
            ...value,
            resetLink: toUrl(entityId, [{ name: parameterName, value: value.value[0] }]),
          });
        });
        break;
      }

      case 'range': {
        currentVariants.forEach((variant) => {
          values.push({
            ...variant,
            value: parameter.default,
            resetLink: toUrl(entityId, [{ name: parameterName }]),
          });
        });
        break;
      }

      case 'switch': {
        currentVariants.forEach((variant) => {
          values.push({
            ...variant,
            name: parameters[parameterId].name,
            value: parameter.default,
            resetLink: toUrl(entityId, [{ name: parameterName }]),
          });
        });
        break;
      }

      default:
    }
  });

  return values;
};

// Вычисляет приоритетные фильтры
export const calculatePriorityFilters = (
  filtrator: Values['filtrator'],
  appliedValues: Values['appliedValues'],
): FiltersGroup[] => {
  // Поскольку фильтр Товары с конструктором есть в популярных запросах, исключаем его из приоритетных фильтров
  const filtersWithoutConstructor = filtrator.filters.filter(
    (filter) => !filter.items.find((item) => item.parameterId === 'configurator'),
  );
  const filters = filtersWithoutConstructor.slice(0, filtrator.countOfPriorityFilters);

  const priorityFilters: FiltersGroup[] = filters.map((filter) => {
    let count = 0;

    filter.items.forEach(({ parameterId }) => {
      const values = appliedValues.filter((appliedValue) => {
        return appliedValue.parameterId === parameterId;
      });

      count += values.length;
    });

    return { ...filter, count };
  });

  return priorityFilters;
};

export const removeFiltersFromUrl = (
  entityId: FiltratorId,
  search: URLSearchParams,
): URLSearchParams => {
  const { filtratorStore } = getStores(entityId);
  const state = getValue(filtratorStore);

  Object.entries(state.parameters).forEach(([parameterId]) => {
    const { type } = state.parameterValues.find((pv) => pv.parameterId === parameterId) || {};
    const isRange = type === 'range';
    const isVariant = type === 'variant';
    const isSwitch = type === 'switch';
    const parameterName = getParameterName(parameterId);

    if (isRange) {
      search.delete(`${parameterName}[from]`);
      search.delete(`${parameterName}[to]`);
    }

    if (isVariant) {
      search.delete(`${parameterName}[]`);
    }

    if (isSwitch) {
      search.delete(parameterName);
    }
  });

  search.delete('sort');

  return search;
};

function createStores(id: FiltratorId, inputData: FiltersData): Instance<Stores> {
  return Cache.createEntity<Stores>(id, () => {
    const countOfPriorityFilters =
      typeof inputData.countOfPriorityFilters === 'number' ? inputData.countOfPriorityFilters : 3;

    const initialData = {
      filters: inputData.filters || [],
      parameters: inputData.parameters || {},
      parameterValues: inputData.parameterValues || [],
      sort: inputData.sort || [],
      groupBy: inputData.groupBy || [],
      categories: inputData.categories || [],
      withoutUrl: inputData.withoutUrl || false,
      countOfPriorityFilters: countOfPriorityFilters,
      search: inputData.search,
    };

    // Начальное состояние фильтров (не изменяется)
    const initialFiltratorStore = createStore<Values['initialFiltrator']>(() => {
      initialFiltratorStore.set(initialData);
    });

    // Текущее состояние фильтров (равно начальному, но меняется во время действий пользователя)
    const filtratorStore = createStore<Values['filtrator']>(() => {
      filtratorStore.set(initialData);
    });

    // Общее количество сущностей, подходящих под фильтр
    const totalCountStore = createStore<Values['totalCount']>(() => {
      totalCountStore.set(inputData.totalCount || 0);
    });

    // Состояние группировки
    const groupsStore = createStore<FiltersSortItemData[]>(() => {
      groupsStore.set(initialData.groupBy);
    });

    // Выбранные категории
    const activeCategoryIdsStore = createDerived(filtratorStore, (filtrator) => {
      return filtrator.categories
        .filter((rubric) => rubric.active)
        .map((rubric) => rubric.id)
        .filter(Boolean);
    });

    // Выбранные фильтры
    const selectedStore = createDerived(filtratorStore, (filtrator) => {
      const selectedFilters: FiltersGroup[] = [];
      const selectedParameters: FiltersParameters = {};
      const selectedParameterValues: FiltersValue[] = [];

      Object.entries(filtrator.parameters).forEach(([parameterId, parameter]) => {
        const parameterValues = filtrator.parameterValues.filter((pv) => {
          return pv.parameterId === parameterId;
        });

        parameterValues.forEach((parameterValue) => {
          const isRange = parameterValue.type === 'range';
          const isVariant = parameterValue.type === 'variant';
          const isSwitch = parameterValue.type === 'switch';
          const defaultValues = parameter.default;

          let flag: boolean;

          if (isRange) {
            flag =
              parameterValue.value[0] !== defaultValues[0] ||
              parameterValue.value[1] !== defaultValues[1];
          }

          if (isVariant) {
            flag = defaultValues.includes(parameterValue.value[0]);
          }

          if (isSwitch) {
            flag = !!defaultValues;
          }

          if (flag) {
            selectedParameters[parameterId] = parameter;
            selectedParameterValues.push(parameterValue);
          }
        });
      });

      filtrator.filters.forEach((filter) => {
        const items = filter.items.filter((item) =>
          Object.keys(selectedParameters).includes(item.parameterId),
        );

        if (items.length > 0) {
          selectedFilters.push({ ...filter, items });
        }
      });

      return {
        filters: selectedFilters,
        parameters: selectedParameters,
        parameterValues: selectedParameterValues,
        sort: filtrator.sort,
        groupBy: filtrator.groupBy,
      };
    });

    // Значения примененных фильтров
    const appliedValuesStore = createStore<Values['appliedValues']>(() => {
      const selected = getValue(selectedStore);
      const appliedValues = calculateAppliedValues(id, selected);

      appliedValuesStore.set(appliedValues);
    });

    // Приоритетные фильтры
    const priorityFiltersStore = createDerived(
      [filtratorStore, appliedValuesStore],
      (filtrator, appliedValues) => {
        return calculatePriorityFilters(filtrator, appliedValues);
      },
    );

    // Ссылка с query-параметрами без фильтров
    const initialSearchParamsStore = createStore<Values['initialSearchParams']>(() => {
      const state = getValue(filtratorStore);
      const search = new URLSearchParams(state.search);
      const searchParams = removeFiltersFromUrl(id, search);

      initialSearchParamsStore.set(searchParams);
    });

    // Ссылка для сброса всех фильтров
    const resetAllUrlStore = createDerived(
      [filtratorStore, initialSearchParamsStore],
      (state, initialSearchParams) => {
        const selectedSort = state.sort.find((item) => {
          return item.selected && +item.id !== 0;
        });

        if (selectedSort) {
          initialSearchParams.set('sort', selectedSort.id);
        }

        return `?${initialSearchParams.toString()}`;
      },
    );

    keepActive(totalCountStore);
    keepActive(groupsStore);

    return {
      initialFiltratorStore,
      filtratorStore,
      totalCountStore,
      appliedValuesStore,
      priorityFiltersStore,
      activeCategoryIdsStore,
      selectedStore,
      resetAllUrlStore,
      initialSearchParamsStore,
      groupsStore,
    };
  });
}

function updateStores(id: FiltratorId, inputData: FiltersData): void {
  const {
    initialFiltratorStore,
    filtratorStore,
    totalCountStore,
    appliedValuesStore,
    selectedStore,
    initialSearchParamsStore,
  } = getStores(id);

  update(initialFiltratorStore, (prev) => ({ ...prev, ...inputData }));
  update(filtratorStore, () => initialFiltratorStore.value);
  update(totalCountStore, () => inputData.totalCount);
  update(appliedValuesStore, () => {
    const selected = getValue(selectedStore);
    return calculateAppliedValues(id, selected);
  });
  update(initialSearchParamsStore, () => {
    const search = new URLSearchParams(inputData.search);
    const searchParams = removeFiltersFromUrl(id, search);

    return searchParams;
  });
}

export function init(id: FiltratorId, initial: FiltersData): void {
  const stores = getStores(id);
  if (stores) {
    updateStores(id, initial);
  } else {
    createStores(id, initial);
  }
}

export const changeRange = (entityId: FiltratorId, { id, from, to }: OptionsRangeData): void => {
  const { filtratorStore } = getStores(entityId);
  const formatedFrom = from ? Number(from.toFixed()) : null;
  const formatedTo = to ? Number(to.toFixed()) : null;

  update(filtratorStore, (filtrator) => ({
    ...filtrator,
    parameters: {
      ...filtrator.parameters,
      [id]: {
        ...filtrator.parameters[id],
        default: [
          formatedFrom || filtrator.parameters[id].default[0],
          formatedTo || filtrator.parameters[id].default[1],
        ],
      },
    },
  }));
};

export const addCheckbox = (entityId: FiltratorId, { id, value }: any): void => {
  const { filtratorStore } = getStores(entityId);

  update(filtratorStore, (filtrator) => {
    const defaultValue = filtrator.parameters[id].default;

    return {
      ...filtrator,
      parameters: {
        ...filtrator.parameters,
        [id]: {
          ...filtrator.parameters[id],
          default: typeof defaultValue === 'number' ? 1 : [...defaultValue, value],
        },
      },
    };
  });
};

export const removeCheckbox = (entityId: FiltratorId, { id, value }: any): void => {
  const { filtratorStore } = getStores(entityId);

  update(filtratorStore, (filtrator) => {
    const defaultValue = filtrator.parameters[id].default;

    return {
      ...filtrator,
      parameters: {
        ...filtrator.parameters,
        [id]: {
          ...filtrator.parameters[id],
          default: typeof defaultValue === 'number' ? 0 : defaultValue.filter((v) => v !== value),
        },
      },
    };
  });
};

// Сбрасывает значение всех фильтров
export const resetAll = (entityId: FiltratorId): void => {
  const { filtratorStore } = getStores(entityId);

  const filtrator = getValue(filtratorStore);
  const parameters: FiltersParameters = {};
  Object.entries(filtrator.parameters).forEach(([key, parameter]) => {
    const value = filtrator.parameterValues.find((v) => v.parameterId === key);

    const defaultValue = {
      range: value.value,
      switch: value.value,
      variant: [],
    };

    parameters[key] = {
      ...parameter,
      default: defaultValue[value.type],
    };
  });

  filtratorStore.set({
    ...filtrator,
    parameters,
  });
};

// Сбрасывает значение отдельного фильтра
export const resetOne: ResetOne = (entityId, value) => {
  const { filtratorStore } = getStores(entityId);
  const filters = getValue(filtratorStore);
  const parameter = filters.parameters[value.parameterId];
  const parameterValue = filters.parameterValues.find((pv) => {
    return pv.parameterId === value.parameterId && pv.name === value.name;
  });

  let newDefault: FiltersParameterDefaultData = [0];
  switch (parameterValue.type) {
    case 'variant': {
      newDefault = parameter.default.filter((v) => v !== parameterValue.value[0]);
      break;
    }

    case 'range':
      newDefault = parameterValue.value;
      break;

    default:
  }

  update(filtratorStore, (filtrator) => {
    return {
      ...filtrator,
      parameters: {
        ...filtrator.parameters,
        [value.parameterId]: {
          ...parameter,
          default: newDefault,
        },
      },
    };
  });
};

// Сбрасывает значения для группы фильтров
export const resetGroup = (entityId: FiltratorId, { group }: OptionsGroupData): void => {
  const { filtratorStore } = getStores(entityId);
  const filtrator = getValue(filtratorStore);
  const parameters = { ...filtrator.parameters };

  group.items.forEach(({ parameterId }) => {
    const values = filtrator.parameterValues.filter((v) => v.parameterId === parameterId);

    let newDefault: FiltersParameterDefaultData = [];
    values.forEach((value) => {
      switch (value.type) {
        case 'variant': {
          newDefault = [];
          break;
        }

        case 'range': {
          newDefault = value.value;
          break;
        }

        default:
      }
    });

    parameters[parameterId] = {
      ...parameters[parameterId],
      default: newDefault,
    };
  });

  filtratorStore.set({ ...filtrator, parameters });
};

// Устанавливает сортировку
export const setSort = (entityId: FiltratorId, option: FiltersData['sort'][0]): void => {
  const { filtratorStore } = getStores(entityId);

  update(filtratorStore, (value) => ({
    ...value,
    sort: value.sort.map((item) => ({
      ...item,
      selected: item.id === option.id,
    })),
  }));
};

// Устанавливает группировку
export const setGroup = (entityId: FiltratorId, option: FiltersData['groupBy'][0]): void => {
  const { groupsStore } = getStores(entityId);

  update(groupsStore, (value) =>
    value.map((item) => ({
      ...item,
      selected: item.id === option.id,
    })),
  );
};

// Обновляет общее количество элементов, которые удовлетворяют фильтрам
export const updateTotalCount = (entityId: FiltratorId, totalCount: number): void => {
  const { totalCountStore } = getStores(entityId);

  totalCountStore.set(totalCount);
};

export const changeCount = (entityId: FiltratorId, filters: FiltersData): void => {
  const { filtratorStore } = getStores(entityId);

  updateTotalCount(entityId, filters.totalCount);
  update(filtratorStore, (filtrator) => {
    const newParameters = {};

    Object.keys(filters.parameters).forEach((id) => {
      const currentParameter = filters.parameters[id];
      const currentParameterValues = filters.parameterValues.find(
        (value) => value.parameterId === id,
      );

      if (currentParameterValues?.type === 'range') {
        const [from, to] = currentParameterValues.value;
        const newFrom = currentParameter.default[0] < from ? from : currentParameter.default[0];
        const newTo = currentParameter.default[1] > to ? to : currentParameter.default[1];

        newParameters[id] = { ...currentParameter, default: [newFrom, newTo] };
        return;
      }
      if (!!currentParameter.default.length) {
        newParameters[id] = currentParameter;
        return;
      }

      newParameters[id] = !!filtrator.parameters[id] ? filtrator.parameters[id] : currentParameter;
      return;
    });

    return {
      ...filtrator,
      ...filters,
      parameters: newParameters,
    };
  });
};

export const applyFilters = (entityId: FiltratorId): void => {
  const { selectedStore, appliedValuesStore } = getStores(entityId);
  const selected = getValue(selectedStore);
  const appliedValues = calculateAppliedValues(entityId, selected);

  appliedValuesStore.set(appliedValues);
};

export const getAppliedFiltersNames = (entityId: FiltratorId): string[] => {
  const { selectedStore, filtratorStore } = getStores(entityId);
  const selected = getValue(selectedStore);
  const appliedValues = calculateAppliedValues(entityId, selected);

  return getFiltersNames(appliedValues, filtratorStore.value.filters);
};

export const resetUnapplied = (entityId: FiltratorId): void => {
  const { appliedValuesStore, filtratorStore, selectedStore } = getStores(entityId);
  const filters = getValue(filtratorStore);
  const appliedValues = getValue(appliedValuesStore);
  const filtersParameters = filters.parameters;
  const selectedParameters = getValue(selectedStore).parameterValues;
  const newDefault: { [key: string]: Array<number | string> } = {};

  appliedValues.forEach((appliedValue) => {
    if (newDefault[appliedValue.parameterId]) {
      newDefault[appliedValue.parameterId].push(...appliedValue.value);
    } else {
      newDefault[appliedValue.parameterId] = appliedValue.value;
    }
  });

  selectedParameters.forEach(({ name, type, parameterId }) => {
    if (!newDefault[parameterId]) {
      switch (type) {
        case 'variant': {
          filtersParameters[parameterId].default = [];
          break;
        }

        case 'range': {
          const item = filters.parameterValues.find((pv) => {
            return pv.parameterId === parameterId && pv.name === name;
          });
          filtersParameters[parameterId].default = item.value;
          break;
        }

        case 'switch': {
          const isParamApplied = !!appliedValues.find((value) => value.parameterId === parameterId);
          // TODO: С бэка сейчас filtersParameters[parameterId].default может быть number. Нужно, чтобы всегда был массив.
          // Пока на бэке нет этой доработки, приводим filtersParameters[parameterId].default к any
          (filtersParameters[parameterId].default as any) = isParamApplied ? 1 : 0;
          break;
        }

        default:
      }
    }
  });

  Object.entries(newDefault).forEach(([parametersId, defaultValue]) => {
    filtersParameters[parametersId].default = defaultValue;
  });

  update(filtratorStore, (filtrator) => {
    return {
      ...filtrator,
      parameters: filtersParameters,
    };
  });
};

export function useFiltrator(entityId: FiltratorId) {
  const { filtratorStore } = getStores(entityId);

  return useStore(filtratorStore);
}

export function useSelected(entityId: FiltratorId) {
  const { selectedStore } = getStores(entityId);

  return useStore(selectedStore);
}

export function useAppliedValues(entityId: FiltratorId) {
  const { appliedValuesStore } = getStores(entityId);

  return useStore(appliedValuesStore);
}

export function useActiveCategoryIds(entityId: FiltratorId) {
  const { activeCategoryIdsStore } = getStores(entityId);

  return useStore(activeCategoryIdsStore);
}

export function usePriorityFilters(entityId: FiltratorId) {
  const { priorityFiltersStore } = getStores(entityId);

  return useStore(priorityFiltersStore);
}

export function useResetLink(entityId: FiltratorId) {
  const { resetAllUrlStore } = getStores(entityId);

  return useStore(resetAllUrlStore);
}

export function useTotalCount(entityId: FiltratorId) {
  const { totalCountStore } = getStores(entityId);

  return useStore(totalCountStore);
}

export function useGroups(entityId: FiltratorId) {
  const { groupsStore } = getStores(entityId);

  return useStore(groupsStore);
}

export function useSelectedGroups(entityId: FiltratorId) {
  const { groupsStore } = getStores(entityId);
  const groups = getValue(groupsStore);

  return groups.find((selectedColor) => selectedColor.selected).id;
}

export function useHasChangedFilters(entityId: FiltratorId) {
  const { initialFiltratorStore, filtratorStore } = getStores(entityId);

  return !equal(initialFiltratorStore.value, filtratorStore.value);
}

export default null;
